@import "../../../sass/partials/variables";
.who-did-it {
  background: radial-gradient(
      70.9% 173.52% at 50% 6.19%,
      rgba(0, 0, 0, 0) 25.31%,
      $black 100%
    ),
    url(https://d1lu1ykcmin7ci.cloudfront.net/image_1.svg);
  background-repeat: repeat-x;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media (min-width: 3000px) {
    background-size: cover;
    & img {
      min-width: 100%;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    background-size: 100%;
  }
  @media screen and (max-width: $breakpoint-sm) {
    background-size: 150%;
  }

  & img {
    @media screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
    @media screen and (max-width: $breakpoint-sm) {
      width: 150%;
    }
  }
}
