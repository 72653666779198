@import "../../../sass/partials/variables";
.hero {
  background-image: url(https://d1lu1ykcmin7ci.cloudfront.net/HeroImg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-x: hidden;
  @media screen and (max-width: $breakpoint-lg) {
    width: 100%;
 }
  @media screen and (max-width: $breakpoint-sm) {
    min-height: 100%;
  }
}

// when winner gets announced hero card disappears, full height image class

.full-height {
  min-height: 110vh;
  @media (min-width: 2000px) {
    min-height: 100vh;
  }
  @media (max-width: 768px) {
    min-height: 42vh;
  }
  @media (max-width: 576px) {
    min-height: 35vh;
  }
}

.hero-content {
  margin-top: 19.875rem;
  margin-bottom: 13.375rem;
  margin-right: $size-70;
  @media screen and (max-width: $breakpoint-lg) {
    margin-right: 0;
    margin-bottom: 3rem;
  }
  @media screen and (max-width: $breakpoint-md) {
    margin-top: 15.625rem;
    padding-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: $breakpoint-sm) {
    margin-top: 8.625rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    margin-left: 1rem;
    padding: 3rem 0rem 2rem 0rem;
  }
  .hero-card {
    max-width: 26.125rem;
    height: auto;
    padding: 2.5rem;
    background: rgba(0, 0, 0, 0.65);

    @media screen and (max-width: $breakpoint-md) {
      padding: 1.5rem;
    
    }
    @media screen and (max-width: 420px) {
      padding: 1.5rem;
    }
    & p {
      font-size: 1rem; // 16px
      line-height: 1.875rem; //30px
      @media screen and (max-width: $breakpoint-md) {
        font-size: 1rem;
        line-height: 2rem;
      }
      @media screen and (max-width: $breakpoint-sm) {
        font-size: 0.9rem;
        line-height: 1.5rem;
      }
    }
    & h1 {
      font-family: $font-family-gochi-hand;
      font-weight: $font-weight-regular;
      font-size: $font-40;
      line-height: $line-height-48;
      width: 100%;
      @media screen and (max-width: $breakpoint-md) {
        font-size: $font-32;
        line-height: $line-height-38;
      }
      @media screen and (max-width: $breakpoint-sm) {
        font-size: $font-28;
        line-height: $line-height-24;
      }
    }
  }
}

.hero-mas {
  padding: $size-10 $size-24 $size-6 $size-24 !important;
  font-size: $font-24;
  line-height: $line-height-28;

  @media screen and (max-width: 2500px) {
    padding-bottom: 9px !important;
  }
  @media screen and (max-width: $breakpoint-sm) {
    padding-bottom: 9px !important;
    padding-top: 12px !important;
  }
}
.btn {
  &.hero-card-btn {
    background: $white;
    color: $black;
    padding: $size-10 $size-24 $size-6 $size-24 !important;
    border-radius: 0px;
    font-family: $font-family-gochi-hand;
    font-size: $font-24;
    line-height: $line-height-28;
    height: 2.75rem; //44px
    font-weight: $font-weight-regular;
    border: 0;
    outline: 0;

    // @-moz-document url-prefix() {
    //   padding: 10px 24px 6px 24px !important;
    // }

    @media screen and (max-width: $breakpoint-md) {
      width: 100%;
      font-size: $font-20;
      line-height: $line-height-20;
    }
  }
}

@media screen and (min-width: 3000px) {

}
@media screen and (min-width: 7000px) {
  .hero-mas {
    height: 2.75rem;
  }
}
@media screen and (max-width: $breakpoint-md) {
  .hero-mas {
    width: 100%;
    padding: $size-10 $size-24 $size-6;
    font-size: 1.25rem !important;
    line-height: 1.25rem !important;
  }
}

.story-release-heading {
  width: max-content;
  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
  }
}

.underline {
  margin: 24px 0px 24px 0px;
  @media (min-width: 3000px) {
    min-width: 5rem;
  }
}
