.canvas-cats {
  width: 100%;
  position: absolute;
  bottom: 0;
}

@media (max-width: 576px) {
  .canvas-cats {
    position: relative;
    margin-top: -50%;
  }
}
