@import "../../../sass/partials/variables";

#footer {
  background: url(../../../assets/images/whodunit-footer.svg) $black;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  margin-top: auto;
  @media screen and (max-width: $breakpoint-lg) {
    background: $black;
  }
}

.width-100 {
  & button {
    width: 100%;
  }
}

.list {
  margin: 4.5rem 0 4.5rem 0;
  font-family: $font-family-inter;
  font-weight: $font-weight-bold;
  font-size: $font-16;
  line-height: $line-height-24;
  color: $white;
  word-wrap: break-word;

  &__copyrights {
    font-weight: $font-weight-regular;
    color: $dark-grey;
  }
  & ul {
    margin-right: 12.125rem;
  }
  & li {
    margin: 0 0 1rem 0;
    & img {
      min-width: 1.419rem;
      margin-right: 1rem;
      margin-bottom: 0.2rem;
    }
  }
  & li {
    & a {
      color: $white;
      &:hover {
        color: $dark-grey !important;
      }
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    margin: 2.5rem 0 2rem 0;
    font-size: $font-14;
    line-height: $line-height-20;
  }
  @media screen and (max-width: $breakpoint-lg) {
    margin: 2rem 0 2rem 0;
  }
}

.footer-touch-heading {
  font-family: $font-family-gochi-hand;
  font-weight: $font-weight-regular;
  font-size: $font-24;
  line-height: $line-height-28;
}

.mb-2rem {
  margin-bottom: 2rem !important;
}

.first-col {
  margin-top: 2rem;
  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 0;
  }
}

.list-gapped {
  @media screen and (max-width: 3900px) {
    margin-top: 6.3rem !important;
  }
  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 2rem !important;
  }
}
