@import "../../../sass/partials/variables";

.filter {
  &__round-btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: $size-4 $size-12 $size-4 $size-11;
    gap: $size-10;
    width: $size-32;
    height: $size-32;
    border-radius: $size-32;
    margin-right: $size-12;
    margin-left: $size-24;
    border: 1px solid $maroon;
    &.fill {
      background: $maroon;
      transition: ease-in-out 0.5s;
    }
  }
  @media screen and (max-width: $breakpoint-lg) {
    justify-content: center;     
  }
}
