@import "../../../sass/partials/variables";
#minted {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35.938rem;
  .width-100 {
    width: 100% !important;
  }

  @media (min-width: 2000px) {
    & .container-xl {
      & .winner-content {
        margin-top: $size-92;
        & h2.solved-heading {
          font-size: $size-24;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    background: url(../../../assets/images/minted-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 43.983rem !important;
  }
  @media screen and (max-width: $breakpoint-sm) {
    & .container-xl {
      & .winner-content {
        & h2.solved-heading {
          font-size: $size-24;
        }
      }
    }
  }
  @media screen and (max-width: $breakpoint-xs) {
    & .container-xl {
      & .winner-content {
        margin-top: $size-48;
        & h2.solved-heading {
          font-size: $size-18;
        }
      }
    }
  }

  @media (max-width: 2000px) {
    & .container-xl {
      & .winner-content {
        margin-top: $size-92;
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    & .container-xl {
      & .winner-content {
        margin-top: $size-80;
      }
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    height: 39.938rem;
  }

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
    // height: 100%;
  }
}

.minted-section-winner {
  height: 50rem !important;
  @media screen and (max-width: $breakpoint-xs) {
    height: 32rem !important;
  }
}

.remaining-minted {
  font-size: $font-32; //32px
  line-height: 2.358rem; //37.72px
  color: $dark-grey;
  font-family: $font-family-gochi-hand;
  @media screen and (max-width: $breakpoint-lg) {
    font-size: $font-24; //24px
    line-height: 2rem;
  }
}

#minted .text-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  width: inherit;

  & h2 {
    @media screen and (max-width: $breakpoint-md) {
      font-size: $font-32;
    }
  }
  & .minted-text {
    @media screen and (max-width: $breakpoint-md) {
      font-size: $font-24;
    }
  }
  @media screen and (max-width: $breakpoint-lg) {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  @media screen and (max-width: $breakpoint-md) {
    margin: 5rem 0 5rem 0;
  }
}

.winner-reveal {
  position: absolute;
  z-index: 1;
  background-image: url(../../../assets/images/paper-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain !important;
  // height: 100%;
  width: 100%;
  @media (max-width: 2800px) {
    top: -4em;
    & .solved-heading {
      font-size: $font-24;
      line-height: $size-28;
    }
  }

  // extremely large displays

  @media screen and (max-width: 8000px) {
    top: -3em;
  }
  @media (max-width: 2000px) {
    top: -2em;
  }
  // for tablets
  @media screen and (max-width: $breakpoint-md) {
    max-width: 80%;
    margin-left: 4rem;
  }
  // for mobiles screen
  @media screen and (max-width: $breakpoint-sm) {
    max-width: 100%;
    margin-left: 0;
  }

  @media screen and (max-width: $breakpoint-xs) {
    top: -4em;
  }
  @media screen and (max-width: 400px) {
    top: -4em;
  }
  @media screen and (max-width: 380px) {
    top: -4.5em;
  }
  @media (max-width: 280px) {
    top: -5em;
  }
}

.solved-heading {
  font-family: Arial;
  font-weight: $font-weight-bold;
  font-size: $size-34;
  line-height: $size-39;
}

.blur-cat-pos {
  margin-top: $size-32;
  margin-bottom: $size-32;
  max-width: 100%;
  @media screen and (max-width: $breakpoint-md) {
    max-width: 50%;
    margin-top: $size-16;
    margin-bottom: $size-16;
  }
  @media screen and (max-width: $breakpoint-sm) {
    max-width: 25%;
  }
  @media screen and (max-width: 2800px) {
    margin-top: $size-16 !important;
    margin-bottom: $size-16 !important;
  }
  @media screen and (max-width: $breakpoint-xs) {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.detective-heading {
  margin-top: $size-32;
  margin-bottom: $size-16;
  font-family: Arial;
  font-size: $size-14;
  line-height: $size-16;
  font-weight: $font-weight-bold;
  @media screen and (max-width: 2800px) {
    margin-bottom: $size-4;
    margin-top: $size-24;
  }
  @media screen and (max-width: $breakpoint-xxl) {
    margin-top: $size-32;
    margin-bottom: $size-16;
  }
  @media screen and (max-width: $breakpoint-xs) {
    margin-top: $size-16;
    margin-bottom: $size-16;
  }
}

.winner-profile {
  margin-bottom: $size-8;
  max-width: 2.75rem; // 44px
  max-height: 2.75rem; // 44px
  border-radius: 50%;
  overflow: hidden;

  & img {
    width: 2.75rem;
    height: 2.75rem;
    object-fit: cover;
  }
}

.winner-name {
  font-family: $font-family-inter;
  font-weight: 500;
  font-size: $size-16;
  line-height: $size-19;
  color: $charcoal;
  text-overflow: ellipsis;
  margin-bottom: $size-32;
  @media (max-width: 2800px) {
    margin-bottom: $size-16;
  }
}

.whodunit-btn-container {
  margin-top: $size-32;
  margin-bottom: $size-80;
  overflow: hidden;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.btn.whodunit-btn {
  background: $black;
  color: $white;
  font-family: $font-family-gochi-hand;

  padding: $size-8 $size-24;
  border-radius: 0;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  @media screen and (max-width: 3900px) {
    font-size: 1rem !important;
    line-height: 1rem !important;
    padding: $size-8 $size-24 !important;
  }
  @media screen and (max-width: 2000px) {
    margin-bottom: 0rem;
  }
  @media screen and (max-width: $breakpoint-xxl) {
    font-size: $size-24 !important;
    line-height: $size-28 !important;
  }
  @media screen and (max-width: $breakpoint-md) {
    font-size: $size-16 !important;
    line-height: $size-16 !important;
  }
}

.mas-whodunit {
  padding: $size-8 $size-24 !important;
  border-radius: 0;
  // box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  -webkit-appearance: none !important;
  appearance: none !important;
  -moz-appearance: none !important;

  @media screen and (max-width: 3900px) {
    font-size: 1rem !important;
    line-height: 1rem !important;
    padding: $size-8 $size-24 !important;
  }
  @media screen and (max-width: $breakpoint-xxl) {
    font-size: $size-24 !important;
    line-height: $size-28 !important;
  }
  &.moz-style {
    @-moz-document url-prefix() {
      @media screen and (max-width: 3900px) {
        font-size: 0.9rem !important;
        line-height: 1rem !important;
        padding: $size-8 $size-24 !important;
      }

      @media screen and (max-width: $breakpoint-xxl) {
        font-size: 1.3rem !important;
        line-height: $size-28 !important;
        padding: $size-8 $size-30 !important;
      }
      @media screen and (max-width: $breakpoint-md) {
        font-size: 0.8rem !important;
        line-height: $size-16 !important;
        padding: $size-8 $size-30 !important;
      }
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    font-size: $size-16 !important;
    line-height: $size-16 !important;
  }
}

.walking-cats {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.winner-content {
  margin-top: $size-92;
}

.border {
  max-width: 100%;

  @media screen and (max-width: $breakpoint-md) {
    max-width: 70%;
  }

  @media screen and (max-width: $breakpoint-sm) {
    max-width: 50%;
  }
  @media screen and (max-width: $breakpoint-xs) {
    display: none;
  }
}

.react-parallax {
  & .react-parallax-bgimage {
    @media screen and (max-width: $breakpoint-md) {
      display: none;
      height: auto;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .winner-mobile {
    margin-bottom: 50em !important;
  }
  .mb-4em {
    margin-bottom: 4em;
  }
}
@media screen and (max-width: $breakpoint-sm) {
  .winner-mobile {
    margin-bottom: 40em !important;
  }
  .mb-4em {
    margin-bottom: 0;
  }
}

/*# sourceMappingURL=index.css.map */
