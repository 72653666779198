@import "../../../sass/partials/variables";

.congrats {
  &__bg {
    background: linear-gradient(
        180deg,
        rgba(33, 35, 48, 0.4) -8.43%,
        rgba(109, 113, 145, 0.4) 200%
      ),
      #08080c url(../../../assets/images/city-bg-Blue.png) repeat-x center;
    // background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $size-40 0;
    margin: $size-40 0;

    @media (min-width: 2000px) {
      background-size: cover;
    }
  }
  & .congrats-text {
    margin-bottom: $size-32;
    @media screen and (max-width: $breakpoint-sm) {
      font-size: $size-22;
      line-height: $size-20;
    }
    @media screen and (max-width: $breakpoint-xs) {
      font-size: $size-16;
    }
  }
  & .winner {
    margin-top: $size-16;
    margin-bottom: $size-32;
    word-break: break-all;

    @media screen and (max-width: $breakpoint-md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @media screen and (max-width: $breakpoint-sm) {
      font-size: $size-16;
      line-height: $size-20;
    }
    @media screen and (max-width: $breakpoint-xs) {
      font-size: $size-12;
      line-height: $size-12;
    }
  }
  & .mystery-solve-text {
    font-size: $size-16;
    line-height: $size-24;
    @media screen and (max-width: $breakpoint-sm) {
      padding: 0rem $size-16 0rem $size-16;
      font-size: $size-16;
      line-height: $size-20;
    }
    @media screen and (max-width: $breakpoint-xs) {
      font-size: $size-12;
      line-height: $size-16;
    }
  }
}

.bold-prize {
  font-size: $size-20;
  line-height: $size-24;
  font-weight: 600;
}

.winner-profile-modal {
  max-width: 4rem; // 64px
  max-height: 4rem; // 64px
  border-radius: 50%;
  overflow: hidden;

  & img {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
  }

  @media screen and (max-width: $breakpoint-sm) {
    max-width: 2.75rem;
    max-height: 2.75rem;
    & img {
      width: 2.75rem;
      height: 2.75rem;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .text-tab-center {
    text-align: center;
  }
}
