@import "../../../sass/partials/variables";

.navbar {
  flex-wrap: nowrap;
}

.admin-header-position {
  @media (max-width: 1500px) {
    min-width: 61% !important;
  }
  @media (max-width: $breakpoint-xl) {
    min-width: 90% !important;
  }
  @media (max-width: $breakpoint-lg) {
    min-width: 58% !important;
  }
}

.header-position {
  min-width: 58%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 4.375rem;
  margin-top: 2.5rem;

  .text-white:not(:first-child) {
    margin-left: 2.5rem;
    @media screen and (max-width: $breakpoint-lg) {
      .text-white:not(:first-child) {
        margin-left: 0;
      }
    }
  }
}

.header-title:active {
  text-decoration: none;
}

.header {
  &__heading {
    @media screen and (max-width: $breakpoint-lg) {
      font-size: $font-48;
      line-height: $line-height-71;
    }
    @media screen and (max-width: $breakpoint-md) {
      font-size: $font-44;
      line-height: $line-height-64;
    }
    @media screen and (max-width: $breakpoint-sm) {
      font-size: $font-32;
      line-height: $line-height-48;
    }
    @media screen and (max-width: $breakpoint-xs) {
      font-size: $font-18;
      line-height: $line-height-20;

      width: 100%;
    }
  }

  &__scroll-heading {
    font-size: $font-40; //40px

    @media screen and (max-width: $breakpoint-lg) {
      font-size: $font-36; //36px
    }
    @media screen and (max-width: $breakpoint-md) {
      font-size: $font-28; //28px
    }
    @media screen and (max-width: $breakpoint-sm) {
      font-size: $font-24; //24px
    }
    @media screen and (max-width: $breakpoint-xs) {
      font-size: $font-20; //24px
    }
  }
}

.tabs {
  font-family: $font-family-gochi-hand;
  font-weight: $font-weight-regular;
  font-size: $font-20;
  line-height: $line-height-24;
  & > {
    margin-left: 2rem;
  }

  @media screen and (max-width: $breakpoint-xs) {
    width: 100%;
  }
  & a {
    @media screen and (max-width: $breakpoint-sm) {
      margin-left: 1rem;
    }
  }
}

.scroll-animation {
  transition: all 0.4s ease-in-out;
}

.navbar-dark {
  .navbar-toggler {
    border: none;
    margin-top: 2.4rem;
    margin-right: 3.5rem;

    @media screen and (max-width: $breakpoint-lg) {
      margin-right: 8rem !important;
    }
    @media screen and (max-width: $breakpoint-sm) {
      margin-right: 4rem !important;
    }
    @media screen and (max-width: $breakpoint-xs) {
      margin-right: 2rem !important;
    }
    &:focus {
      border: none;
    }
    &:focus-visible {
      border: none;
      outline: 0;
    }
    &button:focus:not(:focus-visible) {
      outline: 0 !important;
      border: none;
    }
  }
}

.navbar-toggler-icon {
  width: 1.7rem;
  margin: 0;
  padding: 0;
}

.navbar {
  margin: 0;
  padding: 0;
}

.navbar-collapse {
  flex-basis: content;
  margin-left: 3rem;
}

.btn-close {
  filter: invert(100%);
}

.tabs-mobile-none {
  @media screen and (max-width: $breakpoint-lg) {
    display: none !important;
  }
}

.nav {
  & li.no-border {
    margin-bottom: 1rem;
    &::after {
      content: none;
    }
  }
}

.offcanvas {
  background-color: $bg-light-black;
}

.navbar-dark .navbar-toggler-icon {
  background-size: $size-25 $size-26;
}

.admin-status {
  z-index: 1060;
  right: 1rem;
  min-width: $size-18;
  @media (max-width: $breakpoint-lg) {
    right: 43%;
  }
  @media (max-width: $breakpoint-xs) {
    right: 39%;
  }
}

.avatar-profile {
  max-width: 4rem; // 64px
  max-height: 4rem; // 64px
  overflow: hidden;
  position: relative;

  & .avatar-admin {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
  }
}

.w-45 {
  width: 45%;
}

#dropdown-basic {
  background-image: none;
  background-repeat: no-repeat;
}
.dropdown-basic-btn {
  background-image: none !important;
  background-repeat: no-repeat;
  border: none !important;
  mask: none !important;
  padding-bottom: 0 !important;
  &:hover {
    color: $grey !important;
  }
}

.btn-check:focus + .btn,
.btn:focus {
  outline: none;
  box-shadow: none;
}

.img-dropdown {
  margin-right: $size-70;
}

.form-switch .form-check-input:checked {
  background: rgba(255, 255, 255, 0.1);
  background-repeat: no-repeat;
  background-image: url(../../../assets/images/enabled-switch.png) !important;
  background-position: right top !important;
  border: none;
  color: $grey;
  background-size: auto;
}
.form-switch .form-check-input:focus {
  box-shadow: none;
  border: none;
}

.form-switch .form-check-input {
  background: rgba(255, 255, 255, 0.1);
  background-repeat: no-repeat;
  background-image: url(../../../assets/images/switch.png) !important;
  background-size: auto;
  border: none;
  min-width: $size-40 !important;
  min-height: $size-22 !important;
  width: unset;
  padding-top: 2px;
  cursor: pointer;
}

.winner-reveal-form {
  & .valid-tooltip {
    font-size: $size-12;
    line-height: $size-18;
    right: 0.063rem;
    font-family: $font-family-inter !important;
    font-weight: 500;
    text-align: start;
    min-width: 100%;
    padding: 0.75rem;
    background: $white;
    color: $black;
    transition: ease-in-out 1s !important;
  }
  &:hover {
    & .valid-tooltip {
      display: block !important;
    }
  }
}

.mw-337 {
  min-width: 22.2rem;
}

.mw-90 {
  max-width: 90%;
}

.ms-16 {
  margin-left: $size-16 !important;
}

.enable-form {
  &:hover {
    color: $white !important;
  }
}

@media (min-width: 1790px) {
  .form-switch .form-check-input {
    min-height: $size-20 !important;
  }
  .form-switch .form-check-input:checked {
    min-height: $size-20 !important;
  }
}
@media (min-width: 2000px) {
  .form-switch .form-check-input {
    background-size: contain;
  }
  .form-switch .form-check-input:checked {
    background-size: contain;
  }
}

.offcanvas {
  @media (min-width: 1024px) {
    display: none !important;
  }
}
