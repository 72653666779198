@import "../../../sass/partials/variables";

.amount-nft-input {
  background-color: $bg-light-black;
  color: $white;
  border: none;
  text-align: center;
  font-size: $size-24;
  line-height: $size-28;
  padding-top: $size-6;
  padding-bottom: $size-6;
  &:focus-visible {
    outline: none;
  }
}

@media (max-width: $breakpoint-xlg) {
  .amount-nft-input {
    flex-grow: unset !important;
    min-width: 50%;
  }
}
@media (max-width: $breakpoint-sm) {
  .nft-amount-container {
    flex-direction: column !important;
  }
  .amount-nft-input {
    padding-top: $size-3;
    padding-bottom: $size-3;
  }
}
