@import "../../../sass/partials/variables";

.card-size {
  position: relative;
  max-width: 14rem;
  padding-left: 0 !important;

  & .empty-card-skeleton {
    margin-bottom: $size-40;
  }

  img {
    display: block;
    margin-bottom: $size-40;
    width: 100%;
    cursor: pointer;
    border-radius: 0.25rem;
    &:focus {
      outline: 0.5rem solid $maroon;
    }
  }

  &.unavailable {
    position: relative;
    display: flex;
    position: relative;
    justify-content: center;
    & img {
      filter: grayscale(100%);
    }
    & .unavailable-text {
      position: absolute;
      background-image: linear-gradient(
        180deg,
        $black -45.45%,
        rgba(0, 0, 0, 0) 95.45%
      );

      min-width: 100%;
      z-index: 1;
      @media screen and (max-width: $breakpoint-lg) {
        width: 95%;
      }

      & p {
        margin-left: auto;
        margin-right: auto;
        margin-top: $size-4;
        color: $white;
        font-family: $font-family-gochi-hand;
        font-size: $size-16;
        line-height: $size-16;
        padding: 4px 12px 4px 12px;
        background-color: $black;
        border: 2px solid $white;
        border-radius: $size-20;
        max-width: fit-content;
        @media screen and (max-width: $breakpoint-sm) {
          font-size: $size-12;
          line-height: $size-16;
        }
        @media screen and (max-width: $breakpoint-xs) {
          font-size: $size-10;
          line-height: $size-12;
        }
      }
    }
    & .overlay-on-hover {
      position: absolute;
      z-index: 1;
      transition: 0.5s ease;
      padding-left: 0 !important;
      opacity: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 94.8%;
      height: 84%;

      max-height: min-content;
      background-color: rgba(0, 0, 0, 0.85);
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 2800px) {
        top: -1%;
        left: -1%;
        right: -1%;
        width: 96%;
        height: 86%;
      }
      @media screen and (max-width: $breakpoint-md) {
        width: 95%;
        height: 84%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
        height: 85%;
      }
      @media screen and (max-width: $breakpoint-xs) {
        height: 81%;
      }

      & p.overlay-header {
        color: $white;
        background: none;
        border: none;
        font-family: $font-family-gochi-hand;
        font-size: $size-16;
        line-height: $size-16;
        font-weight: $font-weight-regular;
        margin-bottom: $size-4;
        @media (max-width: 1400px) {
          font-size: $size-14;
          line-height: $size-16;
        }
      }
      & p.overlay-description {
        background: none;
        border: none;
        font-family: $font-family-inter;
        font-size: $size-12;
        line-height: $size-16;
        color: $white;
        @media (max-width: 1400px) {
          font-size: $size-10;
          line-height: $size-12;
        }
        @media screen and (max-width: $breakpoint-xs) {
          font-size: $size-10;
          line-height: $size-12;
        }
      }
      & .opensea-btn {
        padding: $size-3 $size-16 $size-3;
        max-width: 100%;
        font-family: $font-family-gochi-hand;
        font-size: $size-16;
        line-height: $size-16;
        color: $black;
        @media screen and (max-width: 1400px) {
          font-size: $size-8 !important;
          line-height: $size-8 !important;
        }

        @media (max-width: 1200px) {
          font-size: $size-8;
          line-height: $size-8;
        }
        @media screen and (max-width: $breakpoint-xs) {
          font-size: $size-12 !important;
          line-height: $size-16 !important;
        }

        // firefox btn fix

        @-moz-document url-prefix() {
          font-size: 0.9rem;
        }
      }
    }
  }
  &:hover {
    & .overlay-on-hover {
      opacity: 1;
    }
  }
}

.view-container {
  margin-top: $size-16;
  margin-right: 1.2rem;
  @media screen and (max-width: $breakpoint-md) {
    margin-right: 0;
  }
}

.mas-view {
  position: absolute;
  margin-top: 0.5rem;
  background: $maroon;
  color: $white;
  padding: $size-3 $size-16 $size-3;
  font-family: $font-family-gochi-hand;
  font-size: $size-16;
  line-height: $size-16;

  // firefox fix

  @-moz-document url-prefix() {
    font-size: 0.9rem;
  }

  @media screen and (max-width: $breakpoint-4k) {
    margin-top: 0.325rem !important;
  }

  @media screen and (max-width: 1800px) {
    margin-top: 0.3rem !important;
  }
  // ipad screen issue
  @media screen and (max-width: 1024px) {
    margin-top: 0 !important;
    margin-right: 0.155rem;
  }

  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 0.4rem !important;
    margin-right: 0 !important;
  }
  @media screen and (max-width: 1250px) {
    font-size: 0.625rem !important;
    line-height: 0.75rem !important;
  }

  @media screen and (max-width: $breakpoint-xs) {
    margin-top: 0.323rem !important;
  }
  @media screen and (max-width: 1500px) {
    padding: $size-3 $size-16 $size-3;
    font-size: 1rem !important;
    line-height: 1rem !important;
    margin-right: 0.2rem;
    margin-top: 0 !important;
  }

  @media (max-width: $breakpoint-xlg) {
    font-size: $size-8 !important;
    line-height: $size-8 !important;
    margin-top: 0.5rem !important;
  }
  @media (max-width: 1158px) {
    margin-top: 0.3rem !important;
  }
  @media (max-width: $breakpoint-lg) {
    margin-top: 0.5rem !important;
  }
  @media screen and (max-width: $breakpoint-xs) {
    font-size: $size-12 !important;
    line-height: $size-16 !important;
    margin-top: 0.5rem !important;
  }
}
