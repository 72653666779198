@import "../../../sass/partials/variables";

.btn-img {
  margin-right: 1rem;
}

@media (max-width: 576px) {
  .btn-img {
    width: 1.2rem;
    margin-right: 0.75rem;
  }
}

@media (max-width: 370px) {
  .btn-img {
    width: 0.9rem;
    margin-right: 0.4rem;
  }
}

@-moz-document url-prefix() {
  .btn-block-container {
    span {
      color: transparent;
    }
    &:hover {
      span {
        color: $white;
        font-weight: 400;
      }
    }
  }
}

// .btn-block-container {
//   span {
//     color: transparent;
//   }
//   &:hover {
//     span {
//       color: $white;
//       font-weight: 400;
//     }
//   }
// }

@media (min-width: 2000px) {
  .btn-img {
    min-width: 1.2rem;
    margin-right: 0.75rem;
  }
}

.block {
  &__btn-text {
    font-family: $font-family-gochi-hand;
    font-style: $font-style-normal;
    font-weight: $font-weight-regular;
    font-size: $font-24;
    line-height: $line-height-28;

    /* // Extra large devices (large desktops, 1200px and up) */

    @media (max-width: 1200px) {
    }
    /* // Large devices (desktops, 992px and up) */
    @media (max-width: 1000px) {
    }
    @media (max-width: 850px) {
    }
    /* // Medium devices (tablets, 768px and up) */
    @media (max-width: 768px) {
    }
    /* Extereme smaal device */
    @media (max-width: 576px) {
      font-size: 1.1rem;
      line-height: 1rem;
      .btn-img {
        width: 1.2rem;
        margin-right: 0.75rem;
      }
    }

    @media (max-width: 370px) {
      // font-size: 0.8rem;
      // line-height: 0.25rem;
      .btn-img {
        width: 0.9rem;
        margin-right: 0.4rem;
      }
    }
  }
  &__bg-tranparent {
    background: transparent !important;
    border: $size-1 solid $white !important;
    &.mas-block {
      @media screen and (max-width: $breakpoint-sm) {
        font-size: 1.1rem !important;
        line-height: 1rem !important;
        padding-bottom: 6px !important ;
      }
    }
  }
}

.mas-block {
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 1.1rem !important;
    line-height: 1rem !important;
    padding: $size-10 $size-12 $size-6 $size-12 !important;
  }
  @-moz-document url-prefix() {
    @media screen and (max-width: $breakpoint-sm) {
      font-size: 1rem !important;
    }
  }
}

.tooltip-btn {
  background-color: $dark-grey;
  color: $grey;
  &:hover {
    background-color: $dark-grey;
    color: $grey;
  }
  mask: none;
  cursor: default !important;
  padding: $size-10 $size-24 $size-6;
  border-radius: 0px;
  border: none;
}

.transferred-failed-btn {
  background-color: $error-clr !important;
  color: $white !important;
}

.transferred-success-btn {
  background-color: $lime-green !important;
  color: $white !important;
}

@media (max-width: 576px) {
  .tooltip-btn {
    padding: $size-10 $size-12 $size-6 $size-12;
  }
}

@media (max-width: 370px) {
  .tooltip-btn {
    padding: 0.625rem 0.2rem 0.375rem 0.2rem;
  }
}
