@import "../../../sass/partials/variables";

.empty-card-skeleton {
  --card: linear-gradient(to bottom, #313131 100%, transparent 0%);
  --card-height: 12rem;

  --avatar: radial-gradient(
    circle 40px at center,
    #2a2a2a 100%,
    transparent 0%
  );
  --avatar-size: 80px;
  --avatar-position: 24px 24px;

  --title: linear-gradient(to right, #2a2a2a 100%, transparent 0%);
  --title-height: 20px;
  --title-width: 130px;
  --title-position: 24px 120px;

  --subtitle: linear-gradient(to right, #262626 100%, transparent 0%);
  --subtitle-height: 16px;
  --subtitle-width: 150px;
  --subtitle-position: 24px 160px;

  --button: linear-gradient(to bottom, #2a2a2a 100%, transparent 0%);
  --button-height: 40px;
  --button-1-width: 90px;
  --button-1-position: 24px 260px;
  --button-2-width: 90px;
  --button-2-position: 124px 260px;

  --blur-width: 200px;
  --blur-size: var(--blur-width) var(--card-height);
  margin-right: $size-40;

  &:empty::after {
    content: "";
    display: block;

    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
    background-image: linear-gradient(
        90deg,
        rgba(#333, 0) 0,
        rgba(#333, 0.8) 50%,
        rgba(#333, 0) 100%
      ),
      var(--avatar), var(--title), var(--subtitle), var(--button), var(--button),
      var(--card);

    background-size: var(--blur-size), var(--avatar-size) var(--avatar-size),
      var(--title-width) var(--title-height),
      var(--subtitle-width) var(--subtitle-height),
      var(--button-1-width) var(--button-height),
      var(--button-2-width) var(--button-height), 100% 100%;

    background-position: -150% 0, var(--avatar-position), var(--title-position),
      var(--subtitle-position), var(--button-1-position),
      var(--button-2-position), 0 0;
    background-repeat: no-repeat;
    animation: loading 2s infinite linear alternate-reverse;

    @media screen and (min-width: 2000px) {
      --title-width: 92%;
      --subtitle-width: 80%;
    }
    @media screen and (max-width: 1500px) {
      width: 9rem;
      height: 12rem;
    }
    @media screen and (max-width: $breakpoint-xl) {
      width: 7rem;
      height: 12rem;
    }
    @media screen and (max-width: $breakpoint-lg) {
      width: 12rem;
      height: 12rem;
    }
    @media screen and (max-width: 280px) {
      width: 7rem;
      --title-width: 50px;
      --subtitle-width: 80px;
    }
  }
  @media screen and (max-width: $breakpoint-lg) {
    margin-right: 0;
  }
}

.empty-card:empty::after {
  width: 12rem;
  height: 12rem;
}
.empty-card-wide:empty::after {
  width: 100%;
  height: 21rem;
}

.full-size {
  max-width: 100% !important;
}

.card-size {
  position: relative;
  // max-width: 14rem;
  padding-left: 0 !important;

  & .empty-card-skeleton {
    margin-bottom: $size-40;
  }
}

.max-width {
  max-width: 14rem;
}

@media (max-width: 1200px) {
  &__wide {
    max-width: 66%;
  }
}
/* // Large devices (desktops, 992px and up) */
@media (max-width: 1000px) {
  .empty-card-wide:empty::after {
    max-width: 14rem;
    margin: auto;
  }
}
@media (max-width: 850px) {
}

@keyframes loading {
  to {
    background-position: 350% 0, var(--avatar-position), var(--title-position),
      var(--subtitle-position), var(--button-1-position),
      var(--button-2-position), 0 0;
  }
}
