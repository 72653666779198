@import "../../../sass/partials/variables";

.cats-group-container {
  padding: $size-183 $size-70 $size-321 $size-70;
  @media (max-width: 992px) {
    padding-bottom: $size-70;
  }
}

.groups {
  padding-right: $size-51;
  border-right: 0.063rem solid rgba(255, 255, 255, 0.1);
  @media (max-width: 992px) {
    border-right: none;
    border-bottom: 0.063rem solid rgba(255, 255, 255, 0.1);
    padding-right: 0;
    padding-bottom: $size-24;
  }
}
.group-item {
  @media (max-width: 992px) {
    border-bottom: 0.063rem solid rgba(255, 255, 255, 0.1);
    &:last-of-type {
      border-bottom: none;
    }
  }
}

.selected-winner {
  min-width: 100%;
  border-radius: $size-4;
  @media (max-width: 992px) {
    margin-top: $size-14;
    min-width: unset;
    margin-left: 0 !important;
    max-width: 14rem;
  }
  @media (max-width: 576px) {
    margin-top: $size-14;
  }
  @media (max-width: 320px) {
    width: 100%;
  }
}

.nft-details {
  margin-top: $size-16;
  & p {
    font-family: $font-family-gochi-hand;
    font-size: $size-16;
    line-height: $size-18;
  }

  @media (max-width: 992px) {
    margin-left: auto !important;
    margin-right: auto;
    max-width: 14rem;
  }
  @media (max-width: 320px) {
    & p {
      font-size: $size-14;
    }
  }
  @media (max-width: 300px) {
    flex-direction: column;
    text-align: center;
    align-items: center !important;
  }
}

.reward {
  font-size: $size-12;
  line-height: $size-14;
}

.group-info-box {
  @media (max-width: 768px) {
    row-gap: 1.5rem;
  }
}

.prize-btn {
  @media (max-width: 767px) {
    margin-left: 0 !important;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
}

.cats-grp-imgs {
  gap: $size-64;
  @media (max-width: 1500px) {
    gap: 2.6rem;
  }
  @media (max-width: 1430px) {
    gap: 5rem;
  }
  @media (max-width: 992px) {
    gap: $size-32;
  }
}

.overlay-text-transferred {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  top: 0.5rem;
  min-width: 100%;
  & p {
    background: $lime-green;
    color: $white;
    padding: $size-4 $size-12;
    border: 0.125rem solid $white;
    border-radius: 1.25rem;
    font-size: $size-16;
    line-height: $size-16;
  }
}

.overlay-text-failed {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  top: 0.5rem;
  min-width: 100%;
  & p {
    background: $error-clr;
    color: $white;
    padding: $size-4 $size-12;
    border: 0.125rem solid $white;
    border-radius: 1.25rem;
    font-size: $size-16;
    line-height: $size-16;
  }
}

.overlay-text-unclaimed {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  top: 0.5rem;
  min-width: 100%;
  & p {
    background: $black;
    color: $white;
    padding: $size-4 $size-12;
    border: 0.125rem solid $white;
    border-radius: 1.25rem;
    font-size: $size-16;
    line-height: $size-16;
  }
}

.btn-prize-container {
  @media (max-width: 992px) {
    margin-left: auto !important;
    margin-right: auto;
    max-width: 14rem;
  }
}

.transfer-date {
  font-size: $size-12;
  line-height: $size-14;
  @media (max-width: 992px) {
    margin-left: 0 !important;
  }
}

.scroll-to-top {
  box-shadow: none !important;
  @media (max-width: 992px) {
   display: none;
  }
}
