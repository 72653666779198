@import "../../../sass/partials/variables";

.admin-login-bg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(https://d1lu1ykcmin7ci.cloudfront.net/HeroImg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.update-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 18.313rem;
  margin-bottom: 18.313rem;
}

.form-inputs {
  display: flex;
  flex-direction: column;
  & input {
    color: $white;
    max-width: 22.5rem;
    padding: 0.75rem 1rem;
    background-color: transparent;
    border: 0.063rem solid #94a3b8;
    border-radius: $size-4;
    &:focus-visible {
      outline: 0.063rem solid $white;
    }

    &::placeholder {
      color: #94a3b8;
      font-family: $font-family-inter;
      font-size: $size-16;
      line-height: $size-24;
    }
  }
}

// mask div behin btn
.mas-login {
  padding: $size-12 $size-32 $size-12 $size-32 !important;
  font-size: $size-24;
  line-height: $size-24;
  min-width: 22.5rem;
}

.login-btn {
  padding: $size-12 $size-32 $size-12 $size-32 !important;
  font-size: $size-24;
  line-height: $size-24;
  min-width: 22.5rem;
}

// responsiveness
@media (max-width: 768px) {
  .login-form {
    margin-top: 12.1565rem;
    margin-bottom: 12.1565rem;
  }
  .admin-login-bg {
    width: 100%;
    min-height: 100vh;
  }
}

@media (max-width: 576px) {
  .form-inputs {
    & input {
      min-width: unset;
      width: 100%;
    }
  }

  .login-btn-container {
    width: 100%;
  }

  .mas-login {
    min-width: unset;
    width: 100%;
  }

  .login-btn {
    min-width: unset;
    width: 100%;
  }

  .login-btn {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
  .admin-login-title {
    font-size: $size-46;
  }

  .login-form {
    margin-top: 9.1565rem;
    margin-bottom: 9.1565rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media (max-width: 300px) {
  .admin-login-title {
    font-size: $size-32;
  }
  .login-form {
    & h6 {
      font-size: 1rem;
    }
  }
}
