@import "../../../sass/partials/variables";

.attribute {
  padding: $size-151 $size-72 $size-80 $size-70;
  > div {
    border-right: 2px solid $brown;
    @media screen and (max-width: $breakpoint-lg) {
      border-right: none;
      border-bottom: 2px solid $brown;
      padding-top: $size-27;
    }
  }

  div:first-child {
    margin-left: 0;
  }

  div:not(:first-child) {
    padding-left: $size-24;
    @media screen and (max-width: $breakpoint-md) {
      padding-left: 0;
    }
  }

  div:last-child {
    border: none;
    justify-content: center;
  }
  .col-lg-3 div:first-child {
    justify-content: flex-start;
    @media screen and (max-width: $breakpoint-lg) {
      justify-content: center;
    }
  }

  // .col-lg-6 div {
  //   @media screen and (max-width: $breakpoint-xl) {
  //     max-width: max-content;
  //   }
  //   @media screen and (max-width: $breakpoint-lg) {
  //     justify-content: center;
  //   }
  // }

  &__min-width {
    min-width: 77%;
    padding-left: 0 !important;
    @media (max-width: 2000px) {
      min-width: 77%;
    }
    @media screen and (max-width: $breakpoint-lg) {
      min-width: 15.174rem; //242.79px, same as heading
    }
    @media screen and (max-width: $breakpoint-sm) {
      min-width: 11.033rem; //179.53px, same as heading
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

.metadata-nft {
  max-width: 100%;
  margin-top: 2em;
  margin-bottom: $size-12;

  & p {
    font-family: $font-family-gochi-hand;
    font-weight: $font-weight-regular;
    font-size: $font-16;
    line-height: 19px;
  }

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 14rem;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    align-items: center;
  }
}

.mas-mint {
  width: 90%;
  @media screen and (max-width: $breakpoint-lg) {
    width: 14rem;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 1.12rem !important;
    line-height: 1.25rem !important;
  }
  @media screen and (max-width: $breakpoint-xs) {
    width: 100%;
  }
}

.mint-btn-container {
  margin-top: $size-32;
  @media screen and (max-width: $breakpoint-lg) {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: $breakpoint-md) {
    margin-top: $size-16;
  }
  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
  }
}

.mint-btn {
  display: flex;
  justify-content: center;

  // margin-left: 1.5rem;
  font-family: $font-family-gochi-hand;
  font-size: $size-24;
  line-height: $size-28;
  width: 100%;

  @media screen and (max-width: $breakpoint-lg) {
    width: 14rem;
  }

  @media screen and (max-width: $breakpoint-md) {
    max-width: 14rem;
  }
  @media screen and (max-width: $breakpoint-sm) {
    font-size: $size-18;
    line-height: $size-20;
  }
  @media screen and (max-width: $breakpoint-xs) {
    width: 100%;
  }
}

.selected-cat {
  margin-top: $size-30;
  min-width: 100%;
  border-radius: 0.25rem;
  @media screen and (max-width: $breakpoint-lg) {
    max-width: 14rem;
    min-width: unset;
  }
}

.cats-section {
  margin-left: $size-24;
  margin-right: $size-27;
}

.suspects {
  justify-content: flex-start !important;
  padding: $size-32 $size-27 $size-92 $size-24;
  overflow-y: auto;
  max-height: 33rem;
  @media screen and (max-width: $breakpoint-lg) {
    justify-content: center !important;
    padding-right: 0;
  }
  @media screen and (max-width: $breakpoint-sm) {
    --bs-gutter-x: 0 !important;
    // padding-right: 0.4rem;
  }
}
