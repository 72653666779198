// font styles
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap");

// colors
$black: #000000;
$bg-light-black: #0f1016;
$white: #ffffff;
$grey: #cccccc;
$stale-grey: #b4b4b4;
$medium-grey: #f7f4f4;
$dark-grey: #999999;
$bg-light-grey: #e5e5e5;
$maroon: #871616;
$charcoal: #333333;
$brown: #27282d;
$dark-brown: #08080c;
$lime-green: #46B900;
$error-clr: #DC2626;

//font-faces
$font-family-inter: "Inter", sans-serif;
$font-family-gochi-hand: "Gochi Hand", cursive;
$font-family-amatic: "Amatic SC", cursive;
$font-family-arial: "Arial";

//Font-size
$font-80: 5rem;
$font-56: 3.5rem;
$font-48: 3rem;
$font-44: 2.75rem;
$font-40: 2.5rem;
$font-36: 2.25rem;
$font-32: 2rem;
$font-28: 1.75rem;
$font-24: 1.5rem;
$font-22: 1.375rem;
$font-20: 1.25rem;
$font-18: 1.125rem;
$font-16: 1rem;
$font-14: 0.875rem;
$font-12: 0.75rem;
$font-10: 0.625rem;
$font-8: 0.5rem;
$font-6: 0.375rem;
$font-4: 0.25rem;
$font-2: 0.125rem;

//line Height
$line-height-80: 5rem;
$line-height-71: 4.438rem;
$line-height-64: 4rem;
$line-height-56: 3.5rem;
$line-height-48: 3rem;
$line-height-38: 2.375rem;
$line-height-32: 2rem;
$line-height-30: 1.875rem;
$line-height-28: 1.75rem;
$line-height-24: 1.5rem;
$line-height-20: 1.25rem;
$line-height-16: 1rem;
$line-height-14: 0.875rem;
$line-height-12: 0.75rem;
$line-height-10: 0.625rem;
$line-height-8: 0.5rem;
$line-height-6: 0.375rem;
$line-height-4: 0.25rem;
$line-height-2: 0.125rem;

//font-weights
$font-weight-bolder: 700;
$font-weight-bold: 600;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-lighter: 200;
$font-weight-lightest: 100;

// font-styles
$font-style-normal: normal;

//breakpoints
$breakpoint-xs: 320px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xlg: 1400px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 2000px;
$breakpoint-4k: 2560px;

// px to rem values e.g 1rem = 16px
$size-1: 0.063rem;
$size-3: 0.125rem;
$size-4: 0.25rem;
$size-6: 0.375rem;
$size-8: 0.5rem;
$size-10: 0.625rem;
$size-11: 0.688rem;
$size-12: 0.75rem;
$size-13: 0.813rem;
$size-14: 0.875rem;
$size-16: 1rem;
$size-18: 1.12rem;
$size-19: 1.188rem;
$size-20: 1.25rem;
$size-22: 1.428rem; //22.85px
$size-24: 1.5rem;
$size-25: 1.563rem;
$size-26: 1.625rem;
$size-27: 1.688rem;
$size-28: 1.75rem;
$size-30: 1.875rem;
$size-32: 2rem;
$size-34: 2.125rem;
$size-36: 2.25rem;
$size-39: 2.438rem;
$size-40: 2.5rem;
$size-44: 2.75rem;
$size-46: 2.875rem;
$size-48: 3rem;
$size-51: 3.188rem;
$size-56: 3.5rem;
$size-64: 4rem;
$size-70: 4.375rem;
$size-72: 4.5rem;
$size-80: 5rem;
$size-92: 5.75rem;
$size-127: 7.938rem; //127.008px
$size-131: 8.188rem;
$size-151: 9.438rem;
$size-175: 10.938rem;
$size-183: 11.438rem;
$size-191: 11.938rem;
$size-280: 17.5rem;
$size-321: 20.063rem;
$size-328: 20.5rem;
$size-337: 21.063rem;
$size-992: 62rem;

// skeleton loader varibles
$card-padding: 24px;
$card-height: 340px;
$card-skeleton: linear-gradient(lightgrey var($card-height), transparent 0);
$avatar-size: 32px;
$avatar-position: var($card-padding) var($card-padding);
$avatar-skeleton: radial-gradient(
  circle 16px at center,
  white 99%,
  transparent 0
);
$title-height: 32px;
$title-width: 200px;
$title-position: var($card-padding) 180px;
$title-skeleton: linear-gradient(white var($title-height), transparent 0);
$desc-line-height: 16px;
$desc-line-skeleton: linear-gradient(
  white var($desc-line-height),
  transparent 0
);
$desc-line-1-width: 230px;
$desc-line-1-position: var($card-padding) 242px;
$desc-line-2-width: 180px;
$desc-line-2-position: var($card-padding) 265px;
$footer-height: 40px;
$footer-position: 0 calc(var($card-height) - var($footer-height));
$footer-skeleton: linear-gradient(white var($footer-height), transparent 0);
$blur-width: 200px;
$blur-size: var($blur-width) calc(var($card-height) - var($footer-height));
