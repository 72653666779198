@import "../../../sass/partials/variables";

.navbar {
  flex-wrap: nowrap;
}

.header-position {
  min-width: 58%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 4.375rem;
  margin-top: 2.5rem;

  .text-white:not(:first-child) {
    margin-left: 2.5rem;
    @media screen and (max-width: $breakpoint-lg) {
      .text-white:not(:first-child) {
        margin-left: 0;
      }
    }
  }
}

.header-title:active {
  text-decoration: none;
}

.header {
  &__heading {
    @media screen and (max-width: $breakpoint-lg) {
      font-size: $font-48;
      line-height: $line-height-71;
    }
    @media screen and (max-width: $breakpoint-md) {
      font-size: $font-44;
      line-height: $line-height-64;
    }
    @media screen and (max-width: $breakpoint-sm) {
      font-size: $font-32;
      line-height: $line-height-48;
    }
    @media screen and (max-width: $breakpoint-xs) {
      font-size: $font-18;
      line-height: $line-height-20;

      width: 100%;
    }
  }

  &__scroll-heading {
    font-size: $font-40; //40px

    @media screen and (max-width: $breakpoint-lg) {
      font-size: $font-36; //36px
    }
    @media screen and (max-width: $breakpoint-md) {
      font-size: $font-28; //28px
    }
    @media screen and (max-width: $breakpoint-sm) {
      font-size: $font-24; //24px
    }
    @media screen and (max-width: $breakpoint-xs) {
      font-size: $font-20; //24px
    }
  }
}

.tabs {
  font-family: $font-family-gochi-hand;
  font-weight: $font-weight-regular;
  font-size: $font-20;
  line-height: $line-height-24;
  & a {
    margin-left: 2rem;
    &:hover {
      color: $dark-grey !important;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    width: 100%;
  }
  & a {
    @media screen and (max-width: $breakpoint-sm) {
      margin-left: 1rem;
    }
  }
}

.scroll-animation {
  transition: all 0.4s ease-in-out;
}

.navbar-dark {
  .navbar-toggler {
    border: none;
    margin-top: 2.4rem;
    margin-right: 3.5rem;

    @media screen and (max-width: $breakpoint-lg) {
      margin-right: 8rem !important;
    }
    @media screen and (max-width: $breakpoint-sm) {
      margin-right: 4rem !important;
    }
    @media screen and (max-width: $breakpoint-xs) {
      margin-right: 2rem !important;
    }
    &:focus {
      border: none;
    }
    &:focus-visible {
      border: none;
      outline: 0;
    }
    &button:focus:not(:focus-visible) {
      outline: 0 !important;
      border: none;
    }
  }
}

.navbar-toggler-icon {
  width: 1.7rem;
  margin: 0;
  padding: 0;
}

.navbar {
  margin: 0;
  padding: 0;
}

.navbar-collapse {
  flex-basis: content;
  margin-left: 3rem;
}

.btn-close {
  filter: invert(100%);
}

.nav-link {
  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.nav {
  & li {
    margin-bottom: 1rem;
    &::after {
      content: ".";
      display: block;
      height: 1px;
      width: 100%;
      margin: 0.5rem auto;
      text-indent: -9999px;
      border-top: 2px dotted $grey;
    }
  }
}

.offcanvas {
  background-color: $bg-light-black;
}

.navbar-dark .navbar-toggler-icon {
  background-size: $size-25 $size-26;
}
