@import "./variables";

html,
body {
  font-size: 16px;
}

::selection {
  background: $maroon;
  color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6,
body,
p,
a,
span {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  &:hover {
    color: $medium-grey !important;
  }
  &:active {
    text-decoration: underline;
  }
}

h1 {
  font-family: $font-family-amatic;
  font-weight: $font-weight-bold;
  font-size: $font-56;
  line-height: $line-height-71;
}

h2 {
  font-family: $font-family-gochi-hand;
  font-weight: $font-weight-regular;
  font-size: $font-40;
  line-height: $line-height-48;
}
h3 {
  font-family: $font-family-gochi-hand;
  font-style: $font-style-normal;
  font-weight: $font-weight-regular;
  font-size: $font-32;
  line-height: $line-height-38;
}

h5 {
  font-family: $font-family-gochi-hand;
  font-style: $font-style-normal;
  font-weight: $font-weight-regular;
  font-size: $font-24;
  line-height: $line-height-28;
}

h6 {
  font-family: $font-family-gochi-hand;
  font-weight: $font-weight-regular;
  font-size: $font-20;
  line-height: $line-height-24;
}

p {
  font-family: $font-family-inter;
  font-weight: $font-weight-regular;
  color: $dark-grey;
}

// mixin for the button hover animation
@mixin button($bcolor, $url, $x1, $y1, $bor, $col) {
  background: $bcolor;
  -webkit-mask: url($url);
  mask: url($url);
  -webkit-mask-size: $x1 $y1;
  mask-size: $x1 $y1;
  border: $bor;
  color: $col;
}

// scroll bar styling

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  // border: 9px solid rgba(255, 255, 255, 0.5);
  transform: rotate(90deg);
  border-radius: 1rem;
  z-index: 4;
}

.minted-text {
  font-size: $font-80;
  line-height: $line-height-38;
  font-family: $font-family-gochi-hand;
  color: $white;
}

.mas {
  position: absolute !important;
  color: $white;
  text-align: center;
  font-family: $font-family-gochi-hand;
  font-weight: $font-weight-regular !important;
  font-size: $font-24;
  line-height: $line-height-28;
  background-color: $maroon;
  position: absolute;
  overflow: hidden;
  font-weight: bold;
  padding: $size-10 $size-24 $size-6;

  @media screen and (max-width: $breakpoint-sm) {
    padding: 0.625rem 0.2rem 0.375rem 0.2rem;
    font-size: $font-20;
    line-height: $line-height-24;
  }
  @-moz-document url-prefix() {
    font-size: 1.4rem;
  }
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: $dark-grey !important;
  color: $grey !important;
  opacity: 1 !important;
}
.transferred-success-btn.disabled,
.transferred-success-btn:disabled,
fieldset:disabled .transferred-success-btn {
  background-color: $lime-green !important;
  color: $white !important;
  opacity: 1 !important;
}

.btn-dark.disabled,
.btn-dark:disabled,
fieldset:disabled .btn {
  color: #fff !important;
  background-color: #212529 !important;
}

.btn {
  &.btn-primary {
    padding: $size-10 $size-24 $size-6;
    border-radius: 0px;
    border: none;
    @include button(
      $white,
      "../../assets/images/mask.png",
      7100%,
      100%,
      none,
      $black
    );
    cursor: pointer;
    -webkit-animation: ani2 0.7s steps(70) forwards;
    animation: ani2 0.7s steps(70) forwards;

    &:hover {
      -webkit-animation: ani 0.7s steps(70) forwards;
      animation: ani 0.7s steps(70) forwards;
    }
  }
  &.btn-secondary {
    padding: $size-10 $size-24 $size-6 $size-24;
    border-radius: 0px;
    border: none;
    @include button(
      $charcoal,
      "../../assets/images/mask.png",
      7100%,
      100%,
      none,
      $white
    );
    cursor: pointer;
    -webkit-animation: ani2 0.7s steps(70) forwards;
    animation: ani2 0.7s steps(70) forwards;

    &:hover {
      -webkit-animation: ani 0.7s steps(70) forwards;
      animation: ani 0.7s steps(70) forwards;
    }
    &:focus,
    &:active {
      box-shadow: none;
      outline: none !important;
    }
  }
}

.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: radial-gradient(circle, transparent 1%, #3374a2 1%) center/15000%
    #253f55;
}
.ripple:active {
  background-color: #253f55;
  background-size: 100%;
  transition: background 0s;
}

.bg-dark {
  background: $black !important;
}

.mas-sub {
  position: absolute;
  color: $white;
  text-align: center;
  font-family: $font-family-gochi-hand;
  font-weight: $font-weight-regular !important;
  font-size: $font-24;
  line-height: $line-height-28;
  background-color: $maroon;
  position: absolute;
  overflow: hidden;
  font-weight: bold;
  width: 100%;
  padding: $size-10 $size-24 $size-6 $size-24;

  @media screen and (max-width: $breakpoint-sm) {
    font-size: $font-20;
    line-height: $line-height-24;
  }
  @media screen and (max-width: $breakpoint-sm) {
    font-size: $font-18;
    line-height: $line-height-20;
  }
}

.btn-container {
  overflow: hidden;
}
.sub-btn-container {
  max-width: 24.438rem; //391px
  overflow: hidden;
}

.subscribe-btn {
  padding: $size-10 $size-24 $size-6 $size-24;
  border-radius: 0px;
  width: 100%;
  // max-width: 24.438rem; //391px
  font-family: $font-family-gochi-hand;
  font-weight: $font-weight-regular;
  font-size: $font-24;
  line-height: $line-height-28;
  @include button(
    $white,
    "../../assets/images/mask.png",
    7100%,
    100%,
    none,
    $black
  );
  cursor: pointer;
  -webkit-animation: ani2 0.7s steps(70) forwards;
  animation: ani2 0.7s steps(70) forwards;

  &:hover {
    -webkit-animation: ani 0.7s steps(70) forwards;
    animation: ani 0.7s steps(70) forwards;
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: $font-20;
    line-height: $line-height-24;
  }
  @media screen and (max-width: $breakpoint-sm) {
    font-size: $font-18;
    line-height: $line-height-20;
  }
}

.btn.whodunit-btn {
  @include button(
    $black,
    "../../assets/images/mask.png",
    7100%,
    100%,
    none,
    $white
  );
  cursor: pointer;
  -webkit-animation: ani2 0.7s steps(70) forwards;
  animation: ani2 0.7s steps(70) forwards;

  &:hover {
    -webkit-animation: ani 0.7s steps(70) forwards;
    animation: ani 0.7s steps(70) forwards;
  }
}
@keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@-webkit-keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@-webkit-keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

.mas-block {
  width: 100%;
  @media screen and (max-width: $breakpoint-sm) {
  }
  @media screen and (max-width: 370px) {
    padding: 0.625rem 0.2rem 0.375rem 0.2rem !important;
    font-size: 0.8rem;
    line-height: 0.25rem;
  }
}

// common margin styles

.mt-4 {
  margin-top: $size-4 !important;
}
.mb-4 {
  margin-top: $size-4;
}
.mt-12 {
  margin-top: $size-12 !important;
}
.mt-13 {
  margin-top: $size-13 !important;
}

.mt-16 {
  margin-top: $size-16;
}

.mb-16 {
  margin-bottom: $size-16;
}

.pt-16 {
  padding-top: $size-16;
}

.mt-18 {
  margin-top: $size-18;
}

.mt-20 {
  margin-top: $size-20;
}

.mt-22 {
  margin-top: $size-22;
}

.mt-24 {
  margin-top: $size-24;
}

.mt-25 {
  margin-top: $size-25;
}

.mt-26 {
  margin-top: $size-26;
}

.mt-28 {
  margin-top: $size-28;
}

.mt-32 {
  margin-top: $size-32;
}

.mt-32 {
  margin-top: $size-32;
}

.mt-34 {
  margin-top: $size-34;
}

.mt-36 {
  margin-top: $size-36;
}

.mt-40 {
  margin-top: $size-40;
}

.mt-44 {
  margin-top: $size-44;
}

.mt-46 {
  margin-top: $size-46;
}

.mt-51 {
  margin-top: $size-51;
}

.mt-56 {
  margin-top: $size-56;
}

.mt-72 {
  margin-top: $size-72;
}

.ml-24 {
  margin-left: $size-24;
}

.ml-32 {
  margin-left: $size-32;
}

.pt-36 {
  padding-top: $size-36;
}

.br-4 {
  border-radius: $size-4 !important;
}

.br-50 {
  border-radius: 50% !important;
}

.fs-16 {
  font-size: $size-16 !important;
}

.lh-19 {
  line-height: $size-19 !important;
}

.fs-24 {
  font-size: $size-24 !important;
}

.lh-24 {
  line-height: $size-24 !important;
}

.inter {
  font-family: $font-family-inter !important;
}

.amatic {
  font-family: $font-family-amatic !important;
}

.gochi {
  font-family: $font-family-gochi-hand !important;
}

.arial {
  font-family: $font-family-arial !important;
}

.text-bold {
  font-weight: $font-weight-bold !important;
}

.stale-grey {
  color: $stale-grey !important;
}

.grey {
  color: $grey !important;
}

.dark-grey {
  color: $dark-grey !important;
}

.black {
  color: $black !important;
}

.charcoal {
  color: $charcoal !important
}

.error-page {
  margin-top: 50vh;
  margin-bottom: 50vh;
}

.modal-dialog-centered {
  @media (min-width: 576px) {
    min-height: calc(100% - 3.5rem);
  }
  min-height: calc(100% - 3rem);
}

.error-msg-forms {
  color: #fde53b;
  max-width: 22.5rem;
}

.text-blue {
  color: #0094ff !important;
  &:hover {
    color: #fde53b !important;
  }
}

.tooltip-custom {
  font-size: $size-12;
  line-height: $size-18;

  & .tooltip-inner {
    font-family: $font-family-inter !important;
    font-weight: 500;
    text-align: start;
    max-width: 100%;
    padding: 0.75rem;
    background: $white;
    color: $black;
  }
}

.tooltip-arrow {
  &::before {
    border-top-color: $white !important;
  }
}

.tooltip.show {
  opacity: 1;
}

// hide decrement and increment btns
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}