@import "../../../sass/partials/variables";
body {
  background-color: $bg-light-black;
}
.intro-section {
  background-color: $bg-light-black;
}

.intro-content {
  margin-top: 11.938rem; //191px
}

.underline-pos {
  margin: 28px 0px 28px 0;
  @media (min-width: 2000px) {
    min-width: 5.496rem;
  }
}

.mt-84 {
  margin-top: 5.25rem; //84px
}

.button-style {
  // max-width: 10rem; //160px
  font-size: $font-24;
  line-height: $line-height-28;
  font-family: $font-family-gochi-hand;
  font-weight: $font-weight-regular;
  padding: 10px 24px 6px;
  height: 2.75rem;
  margin-bottom: 5.25rem;
}

.align-left {
  text-align: left;
}

.mas-story {
  // hiding hover behind button on small screens
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 1.5rem !important;
    line-height: 1.75rem !important;
  }

  @media screen and (min-width: 3000px) {
    padding: 20px 24px 20px !important;
  }
  @media screen and (min-width: 7000px) {
    padding: 50px 24px 20px !important;
    padding-top: 40px !important;
  }
  // @media screen and (max-width: 368px) {
  //   padding: 10px 18px 6px !important;
  // }
}
