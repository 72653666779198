@import "../../../sass/partials/variables";

.carousel {
  .carousel-inner {
    .carousel-item {
      &:last-of-type {
        &.active {
          + .carousel__spacing {
            .carousel__min-width {
              display: none;
              &.d-none {
                display: block !important;
              }
            }
          }
          + .carousel__spacing {
            .carousel__min-width-prev {
              display: none;
              &.d-none {
                display: block !important;
              }
            }
          }
        }
      }
      &:first-of-type {
        &.active {
          + .carousel__spacing {
            .carousel__min-width-prev {
              display: none !important;
              &.disabled {
                display: block !important;
              }
            }
          }
        }
      }
    }
  }

  .carousel-item p:first-child {
    margin-bottom: 2.5rem;
  }
  .carousel-control-next,
  .carousel-control-prev {
    position: relative;
  }

  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    padding-bottom: 5rem;
  }

  &__carousel-section {
    margin: $size-175 0 $size-80 0;
    padding: 0 $size-70 0 $size-70;
  }

  &__spacing {
    // margin-top: 32rem;
    min-width: 100%;
    position: absolute;
    bottom: 0;
  }
  &__min-width {
    min-width: 9%;
  }
  &__max-height {
    max-height: 45.563rem;
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (max-width: 1200px) {
    .carousel-item p:first-child {
      & br {
        display: none;
      }
    }
  }
  /* // Large devices (desktops, 992px and up) */
  @media (max-width: 1000px) {
    &__carousel-section {
      padding: 0 $size-48 0 $size-48;
    }
  }
  @media (max-width: 850px) {
    .carousel-item p:first-child {
      text-align: left !important;
    }
  }
  /* // Medium devices (tablets, 768px and up) */
  @media (max-width: 768px) {
    &__carousel-section {
      padding: 0 $size-32 0 $size-32;
    }
  }
  /* Extereme smaal device */
  @media (max-width: 576px) {
    &__carousel-section {
      margin: $size-127 0 $size-16 0;
    }
    &__min-width {
      min-width: 26%;
    }
    &__max-height {
      max-height: 100%;
    }
    .carousel-item p:first-child {
      font-size: 0.8rem !important;
      text-align: left !important;
    }
    .mas-next {
      max-width: inherit;
      max-height: inherit;
    }
  }
  @media (max-width: 300px) {
  }
}
