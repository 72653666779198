@import "../../../sass/partials/variables";

.confirm-modal {
  max-width: 30.438rem;
  max-height: 48.438rem;
  &__modal-margin {
    margin: 5.75rem auto;
  }
}

.confirm-modal-container {
  padding-left: $size-40;
  padding-right: $size-40;
  padding-bottom: $size-48;
}
.choice-btns {
  & div:nth-child(2) {
    margin-top: $size-16;
  }
}

@media (max-width: $breakpoint-sm) {
  .confirm-modal-container {
    padding-left: $size-24;
    padding-right: $size-24;
    padding-bottom: $size-28;
  }
}
