body,
html {
  margin: 0;
  padding: 0;
  position: relative;
}

/* to make footer sticky */
html {
  height: 100vh;
}
body,
#root {
  min-height: 100vh;
}
#root {
  display: flex;
  flex-direction: column;
}
