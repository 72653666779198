@import "../../../sass/partials/variables";

.HowItWorks {
  background-color: $bg-light-black;
  &__text-section {
    & h2 {
      @media screen and (max-width: $breakpoint-lg) {
        font-size: $font-32;
        line-height: $line-height-48;
        text-align: center;
      }
      @media screen and (max-width: $breakpoint-sm) {
        font-size: $font-24;
        line-height: $line-height-32;
      }
    }
    & img {
      @media screen and (max-width: $breakpoint-lg) {
        display: flex;
        justify-content: center;
      }
    }
  }
  & .working-text {
    font-size: $font-18;
    line-height: $line-height-32;
    @media screen and (max-width: $breakpoint-lg) {
      text-align: center;
    }
    @media screen and (max-width: $breakpoint-md) {
      font-size: $font-16;
      line-height: $line-height-24;
      & br {
        display: none;
      }
      margin-left: 1rem;
      margin-right: 1rem;
    }
    @media screen and (max-width: $breakpoint-sm) {
      font-size: $font-14;
      line-height: $line-height-16;
    }
  }
  &__margin-tp-btm {
    margin: 5.03rem 0 4.25rem 0;
    & .cat-in-box {
      @media screen and (max-width: $breakpoint-md) {
        width: 100%;
      }
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }
  }
  overflow-x: hidden;
}

.underline {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  @media screen and (max-width: $breakpoint-lg) {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 3000px) {
  .cat-in-box {
    min-width: 100%;
  }
}

.paw {
  min-width: $size-24;
  max-height: $size-22;
  margin-right: $size-12;
  @media screen and (max-width: 380px) {
    max-width: $size-20;
  }
}

.prize-tiers {
  font-size: $size-18;
  line-height: $line-height-32;
  margin-bottom: $size-4;
  display: flex;
  align-items: center;
  @media screen and (max-width: $breakpoint-lg) {
    justify-content: center;
  }
  @media screen and (max-width: $breakpoint-xs) {
    flex-direction: column;
  }
}

.prizes-list {
  margin-bottom: $size-4;
}

.learn-btn {
  max-width: 17.938rem;
  @media screen and (max-width: $breakpoint-lg) {
    margin: auto;
  }
}