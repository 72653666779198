@import "./variables";

@media (min-width: 1790px) {
  // edge cases in some displays e.g 1920x1080
  html,
  body {
    font-size: 19px;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */

// 4k display setting
@media (min-width: 3000px) {
  html,
  body {
    font-size: 40px !important;
  }
  .error-page {
    margin-top: 30vh;
    margin-bottom: 30vh;
  }
}
// 8k display setting
@media (min-width: 7000px) {
  html,
  body {
    font-size: 80px !important;
  }
}

@media (min-width: 1900px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1390px !important;
  }
}

@media (min-width: 2000px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 82rem !important;
  }

  // handling toast width for when font size gets bigger on wide screens

  :root {
    --toastify-toast-width: unset;
  }

  html,
  body {
    font-size: 25px;
  }
}


@media (max-width: 1200px) {
}
/* // Large devices (desktops, 992px and up) */
@media (max-width: 1000px) {
}
@media (max-width: 850px) {
}
/* // Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .sub-btn-container {
    margin: auto;
  }
}
/* Extereme smaal device */
@media (max-width: 576px) {
  h5 {
    font-size: 1.4rem;
    line-height: 1.55rem;
  }
  p {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.7rem;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 1.375rem;
  }
  .btn {
    &.btn-primary {
      padding: 10px 12px 6px 12px;
    }
    &.btn-secondary {
      padding: 10px 12px 6px 12px;
    }
  }
  .tooltip-inner {
    padding: 0.3rem !important;
    max-width: 90% !important;
  }
}
@media (max-width: 370px) {
  h2 {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  h5 {
    font-size: 1rem;
    line-height: 0.75rem;
  }
  p {
    font-size: 0.75rem;
  }
  .btn {
    &.btn-primary {
      padding: 0.625rem 0.2rem 0.375rem 0.2rem;
    }
    &.btn-secondary {
      padding: 0.625rem 0.2rem 0.375rem 0.2rem;
    }
  }
}
