@import "../../sass/partials/variables";

.faqs {
  padding: $size-191 $size-280 $size-992 $size-280;
  position: relative;
  @media screen and (max-width: $breakpoint-xlg) {
    padding-left: $size-92;
    padding-right: $size-92;
  }
  @media screen and (max-width: $breakpoint-xl) {
    padding-bottom: $size-92;
  }
  @media screen and (max-width: $breakpoint-md) {
    padding-left: $size-48;
    padding-right: $size-48;
    padding-bottom: $size-80;
  }
  @media screen and (max-width: $breakpoint-sm) {
    padding-left: $size-24;
    padding-right: $size-24;
    padding-bottom: $size-48;
  }
}

.faq-heading {
  line-height: $size-40;
}

.faq-section {
  margin-bottom: $size-40;
  padding-left: 44px;
  position: relative;
  &::before {
    content: url(../../assets/images/underline-sm.svg);
    display: block;
    max-width: $size-26;
    max-height: $size-4;
    margin-right: $size-18;
    position: absolute;
    left: 0;
    font-size: $size-26;
    line-height: $size-4;
  }
}

.question {
  margin-bottom: $size-4;
}
