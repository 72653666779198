@import "../../../../sass/partials/variables";

.font-gouchi {
  font-family: $font-family-gochi-hand;
  line-height: $size-24;
  &:first-child {
    margin-top: $size-24;
  }
}

.dropdown {
  padding-left: 0 !important;
  & button {
    padding: $size-12 $size-16;
    gap: $size-8;
    border: 1px solid #dee2e6;
    border-radius: $size-4;
    background-color: transparent;
    font-family: "Inter";
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: $size-16;
    line-height: $size-24;
    color: $grey;
    background-color: $bg-light-black;
    text-align: start;
    background-image: url(../../../../assets/images/Vector-down.svg);
    background-position: right 0.75rem center;
    background-repeat: no-repeat;
    background-size: $size-16 $size-12;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
    margin: $size-4 0 $size-12 0;
    width: 100%;

    &.btn-dark {
      &:hover {
        background-color: $bg-light-black;
        color: $grey;
        border: 1px solid #dee2e6;
      }
      &.dropdown-toggle {
        background-color: none;
        border: 1px solid #dee2e6;
      }
    }

    &.btn-dark:focus {
      background-color: $bg-light-black;
      color: $grey;
    }
    &.btn-dark:active {
      outline: none;
      background-color: $bg-light-black;
      color: $grey;
    }

    &:after {
      display: none;
    }

    &[aria-expanded="true"] {
      background-image: url(../../../../assets/images/vector-up.svg);;
    }

    @media screen and (max-width: $breakpoint-sm) {
      background-size: $size-10 $size-12;
      background-position: right 0.32rem center;
    }
  }
}

.dropdown-menu {
  background-color: $bg-light-black;
  width: 100%;
  padding-left: 0 !important;
  padding: 0;
  border: 0.063rem solid $white;
  margin-top: $size-10;
  & .dropdown-item {
    color: $grey;
    &:active {
      background-color: $bg-light-black;
    }
    &:hover {
      background-color: $bg-light-black;
      color: $black;
    }
  }
}

.group-select {
  overflow: hidden;
  text-overflow: ellipsis;
}

#dropdown-dark-button {
  overflow: hidden;
  text-overflow: ellipsis;
}
